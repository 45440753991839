<template>
  <div class="ds-wrapper">
    <h5 class="block text-base mb-4">Preview</h5>
    <div :class="`${product.marketingBanner ? 'preview-card' : ''} bg-page border-container`">
      <vs-card :class="`${product.marketingBanner ? '' : 'with-out-banner'} pay-in-5 ds-card max-w-sm py-8 mx-auto preview-summary-card`">
        <div v-if="product.marketingBanner" slot="header">
          <p id="marketing-banner" v-html="product.marketingBannerText"></p>
        </div>
        <div>
          <div class="mx-4 pt-6">
            <h3 class="mb-4">{{ product.productName }}</h3>
            <p class="para-def-light-anc mb-6">{{ product.productText }}</p>
            <div class="col-span-3 mb-6">
              <p v-if="product.productType === 'B2C_BNPL'" class="text-primary text-sm light">{{ product.noOfPayments }} {{ product.paymentFrequency.toLowerCase() }} payments of</p>
              <h2 class="display-2">{{ product.productType === "B2C_BNPL" ? "$210.00" : "$1000.00" }}</h2>
            </div>

            <vs-button class="w-full mb-3" size="large">Select</vs-button>
          </div>
          <div class="bg-container-80 md:grid md:grid-cols-12 py-6 px-4 md:py-8 md:px-6 mt-6 sm:mt-0" v-if="showDetails">
            <TransactionSummary :product="product" />
          </div>

          <hr class="mb-4" />

          <div class="flex justify-between items-center mt-4 mx-4 pb-2">
            <h3 class="text-body-dark ancillary uppercase">{{ showDetails ? "hide" : "show" }} details</h3>
            <vs-icon type="flat" class="text-body-dark material-icons-outlined cursor-pointer" :icon="showDetails ? 'expand_less' : 'expand_more'" icon-size="large" @click="toggleDetails" />
          </div>
        </div>
      </vs-card>
    </div>
  </div>
</template>

<script>
  import TransactionSummary from "./TransactionSummary.vue";

  export default {
    data() {
      return {
        showDetails: false
      }
    },

    props: ["product"],

    components: {
      TransactionSummary
    },

    methods: {
      toggleDetails() {
        this.showDetails = !this.showDetails;
      },

      payable() {
        let frequency = this.product.paymentFrequency;
        let payable = "";

        if (frequency === "WEEKLY") {
          payable = "1 week";
        } else if (frequency === "FORTNIGHTLY") {
          payable = "2 weeks";
        } else if (frequency === "MONTHLY") {
          payable = "1 month";
        } else if (frequency === "DAILY") {
          payable = "1 day";
        }

        return payable;
      },
    }
  }
</script>

