<template>
  <div class="radiobox radio-default" :class="{ 'radio-active': isActive }" @click="model=radioValue">
    <div :class="{ 'background-active': isActive }" class="radiobox-pads">
      <div class="radio-wrapper h-auto w-min" :class="{ 'flex-row-reverse': reverse }">
        <p class="font-normal text-base" style="white-space:nowrap;">{{ title }}</p>
        <vs-radio v-model="model" :vs-name="name" :vs-value="radioValue" class="radio p-0 ml-4 mb-0 margin-break" />
      </div>
    </div>
    <div class="description pl-4 pr-2 md:pb-5 sm:pb-8 pt-4" v-if="description">
      <p class="text-xs">{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "radiobox",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      required: true
    },
    name: {
      type: String,
      required: true,
    },
    radioValue: {
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    isActive() {
      return this.model === this.radioValue;
    }
  }
};
</script>