<template>
  <div class="ds-wrapper">
    <h5 class="block text-base mb-4">Preview</h5>
    <div class="preview-card bg-page border-container preview pay-later-product-preview">
      <div class="flex flex-col items-center">
        <div class="dashed-border m-auto my-8 w-full max-w-420"></div>
        <div class="flex justify-between items-center mb-4 w-full max-w-420" v-if="product.productConfigType == 'THREE_PARTY'">
          <p class="text-primary">{{ product.summaryHeader }}</p>
        </div>
        <div class="flex justify-between items-center mb-4 w-full max-w-420">
          <p>{{ product.summaryAmountLabel }}</p>
          <p class="text-sm">$ 1,000.00</p>
        </div>
        <div class="flex justify-between items-center w-full max-w-420">
          <p>{{ product.summaryFeeLabel }}</p>
          <p class="text-sm">$ 50.00</p>
        </div>
        <div class="total-amt"></div>
        <div class="flex justify-between items-center w-full max-w-420 mt-6">
          <p class="text-primary">{{ product.summaryTotalLabel }}</p>
          <p class="text-primary">$ 1,050.00</p>
        </div>
        <div v-if="product.productType !== 'B2C_BNPL'" class="max-w-420 mt-6">
          <p class="text-ancillary">{{ `${product.summaryMessage} 24 Jun 2023` }}</p>
        </div>
        <div v-if="String(product.productType).includes('BNPO')" class="max-w-420 mt-3">
          <p class="text-ancillary">{{ product.summaryConfigurableFeeText }}</p>
        </div>
      </div>
      <div class="flex flex-wrap justify-center my-16">
        <vs-button color="primary" size="large" class="w-full max-w-400">{{ product.summaryButtonText || "Accept plan" }} {{ product.productType == "B2C_BNPL" ? "& pay $210.00 now" :"" }} </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["product"],
  }
</script>

