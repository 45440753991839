<template>
  <div class="md:col-start-1 md:col-end-12">
    <template v-if="product.productType === 'B2C_BNPL'">
      <div class="flex justify-between">
        <h5>{{ product.dateLabel || "Payment Date" }}</h5>
        <h5>{{ product.amountLabel || "Amount" }}</h5>
      </div>

      <hr class="mt-2.5 mb-4" />

      <div class="grid gap-y-2 h-41 overflow-y-auto">
        <div class="flex justify-between"><p>Today</p><p>$ 210.00</p></div>
        <div class="flex justify-between"><p>12 Mar 2021</p><p>$ 210.00</p></div>
        <div class="flex justify-between"><p>12 Apr 2021</p><p>$ 210.00</p></div>
        <div class="flex justify-between"><p>12 May 2021</p><p>$ 210.00</p></div>
        <div class="flex justify-between"><p>12 Jun 2021</p><p>$ 210.00</p></div>
        <div class="flex justify-between"><p>12 Jul 2021</p><p>$ 210.00</p></div>
      </div>
      <hr class="my-6" />
      <div class="flex justify-between mb-6">
        <h3>{{ product.totalAmountLabel || "Total" }}</h3>
        <h3>$ 1,050.00</h3>
      </div>
      <p class="text-ancillary">{{ product.feeText }}</p>
    </template>
    <template v-else>
      <div class="text-center">
        <p class="text-ancillary">{{ product.message }}</p>
        <h4>24 June 2023</h4>
      </div>
      <div class="flex flex-col items-center">
        <div class="dashed-border m-auto my-8 w-full max-w-420"></div>
        <div class="flex justify-between items-center mb-4 w-full max-w-420">
          <p>{{ product.purchaseAmountLabel || "Purchase amount" }}</p>
          <p class="text-sm">$ 1,000.00</p>
        </div>
        <div class="flex justify-between items-center w-full max-w-420">
          <p>{{ product.feeLabel || "Service fee" }}</p>
          <p class="text-sm">$ {{ product.feeModel === "MSF_ADDED" ? addZeroes(this.product.msf * 0.01 * 1000) : 0.00 }}</p>
        </div>
        <div class="total-amt"></div>
        <div class="flex justify-between items-center w-full max-w-420 mt-6">
          <p class="text-primary">{{ product.totalAmountLabel || "Total" }}</p>
          <p class="text-primary">$ {{ product.feeModel === "MSF_ADDED" ? addZeroes(product.msf * 0.01 * 1000 + 1000) : addZeroes(1000) }}</p>
        </div>
        <div class="text-center mt-6" v-if="String(product.productType).includes('BNPO')">
          <p class="text-ancillary">{{ product.configurableFeeText }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    props: ["product"],

    methods: {
      addZeroes(num) {
        num = String(num);
        return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
      },
    }
  }
</script>

